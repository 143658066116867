$fa-font-path: "/dist/fonts/";


@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/brands";


@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype');
}


@import "startbootstrap-sb-admin-2/scss/sb-admin-2";


@import "@selectize/selectize/dist/scss/selectize";
@import "selectize-bootstrap4-theme/scss/selectize.bootstrap4";

@import "datatables.net-bs4/css/dataTables.bootstrap4";


@import "../js/lib/@form-validation/umd/styles/index";